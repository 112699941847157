
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {TABLE_FOOTER_OPTIONS} from "@/misc/TableFooterOptions";
import {DateTime} from "luxon";
import TransactionStatistic from "@/models/TransactionStatistics";
import {TransactionStatisticType} from "@/enum/TransactionStatisticType.enum";

@Component
export default class StatisticsListComponent extends Vue {
  @Prop({required: false, default: []})
  public transactions!: TransactionStatistic[] | null;

  @Prop({default: false})
  private isLoading!: boolean;

  @Prop({required: true})
  private onPickerReset!: () => Promise<void>;

  @Prop({required: true})
  private onPickerMonthChanged!: (value: string) => Promise<void>;

  private get transactionData() {
    if(!this.transactions) return [];
    return this.transactions;
  }

  private translationBody(item: TransactionStatistic) {
    switch(item.type) {
      case TransactionStatisticType.CANCELLED:
        return {'service': item.values[0], 'date': this.$formatDateFromString(item.values[1])};
      case TransactionStatisticType.SUBSCRIBED:
        return {'value': item.values[0]};
      case TransactionStatisticType.DEPOSITED:
        return {'value': this.$formatCurrency(item.values[0])};
      case TransactionStatisticType.WITHDRAWN:
        return {'value': this.$formatCurrency(item.values[0])};
      case TransactionStatisticType.PAY_WITH_MERWAYS:
        return {'value': this.$formatCurrency(item.values[0])};
    }
  }

  /**
   * options for the footer
   * @private
   */
  private footerOptions = TABLE_FOOTER_OPTIONS;

  /**
   * picked month
   * @private
   */
  private picker: string = "";

  /**
   * flag that indicates if month picker menu is opened
   * @private
   */
  private menu: boolean = false;

  private async onPickerMonthReset() {
    this.menu = false;
    await this.onPickerReset();
    this.picker = '';
  }

  private async onMonthChanged() {
    this.menu = false;
    await this.onPickerMonthChanged(this.picker);
  }

  /**
   * headers for the table
   * @private
   */
  private headers = [
    {
      text: this.$t('GENERAL.TRANSACTION'),
      align: "start",
      sortable: true,
      value: "containerId",
    },
    {
      text: this.$t('GENERAL.MONTH'),
      align: "start",
      sortable: true,
      value: "rentedOn",
      formatter: this.translateDate,
    },
  ];

  /**
   * formats the date that was picked
   * @private
   */
  private get getFormattedDate(): string {
    const date = this.picker;
    if(!date || date === '') return this.$t('GENERAL.EVERY_MONTH').toString();
    const month = this.translateDate(date);
    const dateTime = DateTime.fromISO(date?.toString());
    return month + " " + dateTime.toFormat(this.$t('GENERAL.YEAR_PICKER').toString());
  }

  /**
   * translates the date with the custom translater plugin, checks if the date is set to avoid 'invalid dateTime's in
   * the table
   * @param date
   * @private
   */
  private translateDate(date?: string): string {
    // if date doesn't exist, just return placeholder
    if (!date) return "-";

    // @ts-ignore
    return this.$t(`GENERAL.MONTHS.${this.$formatMonthByString(date).toUpperCase()}`).toString();
  }

  private isWithdrawn(item: TransactionStatistic) {
    return item.type === TransactionStatisticType.WITHDRAWN;
  }

  private isDeposit(item: TransactionStatistic) {
    return item.type === TransactionStatisticType.DEPOSITED;
  }

  private isSubscribed(item: TransactionStatistic) {
    return item.type === TransactionStatisticType.SUBSCRIBED;
  }

  private iSCancelled(item: TransactionStatistic) {
    return item.type === TransactionStatisticType.CANCELLED;
  }

  private isPayWithMerways(item: TransactionStatistic) {
    return item.type === TransactionStatisticType.PAY_WITH_MERWAYS;
  }
}
